import axios from "axios";
import { baseURL } from "../global/global";
import { fetchDashboardData } from "./dashboard";
import { fetchAllProject, fetchLanguageForTranslate } from "./video";
import { fetchSocialAccounts } from "../actions/createPodreel";
import {
  getWhiteLabelUsers,
  getPaymentMethods,
  getTemplatMethods,
  getWhiteLabelMembership,
} from "./whiteLabel";

import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  USER_LOADED,
  LOGOUT,
  CLEAR_TEMPLATE_VIDEO,
  CLEAR_SOCIAL_ACCOUNTS,
  CLEAR_DASHBOARD_DATA,
  OPEN_CLOSE_MODAL,
} from "./Types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert, removeAlert } from "./alert";
import { removeLoader, setLoader, setVerifyLoader } from "./loader";

// Load User
export const loadUser =
  (load = true) =>
  async (dispatch) => {
    if (load === true) {
      dispatch(setVerifyLoader());
    }
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.post(`${baseURL}api/verify-token`);
      if (res.data.status === true) {
        dispatch({ type: USER_LOADED, payload: res.data });
        dispatch(fetchDashboardData(res.data.data.user_id));

        dispatch(removeLoader());
      } else {
        dispatch({ type: AUTH_ERROR });
        dispatch(removeLoader());
      }
    } catch (err) {
      dispatch(removeLoader());
      dispatch({ type: AUTH_ERROR });
    }
  };

// Login
export const login = (email, password, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`${baseURL}api/validate-login`, body, config);

    if (res.data.status === true) {
      console.log(res);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
      dispatch(loadUser());
      dispatch(
        fetchAllProject(JSON.stringify({ user_id: res.data.data.user.user_id }))
      );
      dispatch(removeAlert());
      dispatch(removeLoader());
      console.log(res.data.data.user.user_id)
      dispatch(fetchSocialAccounts(res.data.data.user.user_id));
      
      history.push("/dashboard");
    } else {
      dispatch({ type: LOGIN_FAIL });
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
    dispatch(removeLoader());
    dispatch(setAlert("Server Error, Please try again", "danger"));
  }
};

// Master-Login
export const masterLogin = (email, token, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, token });

  try {
    const res = await axios.post(`${baseURL}master-login`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
      dispatch(removeAlert());
      dispatch(removeLoader());
      history.push("/dashboard");
    } else {
      dispatch({ type: LOGIN_FAIL });
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
    dispatch(removeLoader());
    dispatch(setAlert("Server Error, Please try again", "danger"));
  }
};

// Forgot password
export const forgot_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(`${baseURL}forgot-password`, body, config);
    if (res.data.status === true) {
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Reset password
export const reset_password =
  (userPassword, token, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { password, confirm_password } = userPassword;

    const body = JSON.stringify({ password, confirm_password, token });

    try {
      const res = await axios.post(`${baseURL}reset-password`, body, config);
      console.log(res);
      if (res.data.status === true) {
        console.log(body);
        dispatch(removeAlert());
        dispatch(removeLoader());
        history.push("/");
      } else {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch(removeLoader());
      }
    } catch (err) {
      console.log(err);
    }
  };

//Update Personal Information

export const updatePersonalInformation = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...user });
  try {
    const res = await axios.post(`${baseURL}api/change-userdata`, body, config);
    if (res.data.status === true) {
      dispatch(removeAlert());
      await dispatch(loadUser());
      dispatch(setAlert(res.data.message, "success"));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
  }
};

// Update Email
export const updateEmail = (user, setLoad) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...user, type: "changeUserEmail" });

  try {
    const res = await axios.post(`${baseURL}api/change-email`, body, config);
    if (res.data.status === true) {
      // dispatch(removeLoader());
      dispatch(removeAlert());
      await dispatch(loadUser(false));
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
    setLoad(false);
  } catch (err) {
    console.log(err);
    setLoad(false);
  }
};

// Change Password
export const updatePassword = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...user, type: "changeUserPassword" });

  try {
    const res = await axios.post(`${baseURL}api/change-password`, body, config);
    if (res.data.status === true) {
      dispatch(removeAlert());
      await dispatch(loadUser());
      dispatch(setAlert(res.data.message, "success"));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
  }
};

// AvatarUpdate
export const updateAvatar =
  (data, close, setUploadStuff, setSourceCancel) => async (dispatch) => {
    let source = axios.CancelToken.source();
    setSourceCancel(source);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        if (percentage < 99) {
          setUploadStuff((value) => ({
            ...value,
            uploadedPercentage: percentage,
          }));
        }
      },
      cancelToken: source.token,
    };

    try {
      const res = await axios.post(
        `${baseURL}api/change-profile`,
        data,
        config
      );
      if (res.data.status === true) {
        await dispatch(loadUser());
        dispatch(setAlert(res.data.message, "success"));
        dispatch(removeLoader());
        close();
      } else {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch(removeLoader());
      }
    } catch (err) {
      console.log(err);
    }
  };

// close dashboard modal
export const openCloseModal = (data) => async (dispatch) => {
  dispatch({ type: OPEN_CLOSE_MODAL, payload: data });
};

// close for always
export const closeShowPopup = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...data });

  try {
    const res = await axios.post(
      `${baseURL}api/add-popup-status`,
      body,
      config
    );
    if (res.data.status === true) {
      dispatch(fetchShowPopup({ user_id: data.user_id }));
      dispatch(setLoader(false));
    }
  } catch (err) {
    console.log(err);
    dispatch(setLoader(false));
  }
};

// fetch for popup, will it show or not
export const fetchShowPopup = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ ...data });
  try {
    const res = await axios.post(
      `${baseURL}api/fetch-popup-status`,
      body,
      config
    );
    if (res.data.status === true) {
      let val = res.data.data.status === "0" ? true : false;
      dispatch(openCloseModal(val));
    } else if (res.data.status === false) {
      dispatch(openCloseModal(true));
    }
  } catch (err) {
    console.log(err);
  }
};

// Logout
export const logout = (id) => async (dispatch) => {
  dispatch(removeLoader());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id });
  try {
    const res = await axios.post(`${baseURL}api/logout`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: LOGOUT,
      });
      dispatch({
        type: CLEAR_TEMPLATE_VIDEO,
      });
      dispatch({
        type: CLEAR_SOCIAL_ACCOUNTS,
      });
      dispatch({
        type: CLEAR_DASHBOARD_DATA,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
