import React, {useState, useEffect, useRef} from 'react';
import { baseURL } from "../../../../global/global";
import { useSelector, useDispatch, connect} from "react-redux";
import { saveTransAudioCaption, generatedCaptions, translatedCaptions, updatedTranslatedCaptions, updatedGeneratedCaptions} from "../../../../actions/createPodreel";
import axios from "axios";


const Caption = () => {

const [lang, setLang] = useState([]);
const [sentences, setSentences] = useState([]);
const [translatedCaption, setTranslatedCaption] = useState([]);
const [translated, setTranslated] = useState(false);
const dispatch = useDispatch();
const [loader, setLoader] = useState(false);
const user_id =  useSelector(state => state.auth.user.user_id);
const trimAudio =  useSelector(state => state.createPodreel.trimmed_audio_data);
const translatedLang =  useSelector(state => state.createPodreel.audio_data.audioTranslateCaption);

const [changeCaption, setChangeCaption] = useState();

const [translate, setTranslate] = useState({
  index : "",
  option :"",
  langCode:"",
  transName:""
})


useEffect(() => {
    axios.get("https://app.podreel.io/api/translate-languages")
    .then((res) => {
      if(res.data.status === true){
        setLang(res.data.data.tranlatelanguages);
      }
    })
},[]);


useEffect(() => {
  setSentences([]);
  setTranslatedCaption([]);
},[])

    useEffect(() => {
      setSentences([]);

        if(trimAudio.campaign_id !== "" && trimAudio.job_name !== ""){
        const config = {
          headers: {
            "Content-Type": "application/json"
          },
        }
        
        const data = {
          user_id: user_id,
          campaignid: trimAudio.campaign_id,
          job_name: trimAudio.job_name
        };
    
        setLoader(true);
        function getTrimmedAudio() {
          axios.post(`${baseURL}api/audio-transcribedata-get`, data, config)
          .then((res) =>{
            if(res.data.status === true){
              setLoader(false);
              console.log(res.data.transcriberesult)
            setSentences(res.data.transcriberesult.sentences);
            dispatch(generatedCaptions(res.data.transcriberesult.sentences));
            clearInterval(interval);
            }
          })
        }
        getTrimmedAudio();
        const interval = setInterval(() => getTrimmedAudio(), 3000)
       
      }
    }, [trimAudio])
    
    
    
    const audioTransCaption = (e) => {
      let index = e.target.selectedIndex;
      let option = e.target.childNodes[index];
      let langCode =  option.getAttribute('langcode');
      let transName = e.target.value;


      setTranslate({
        index : e.target.selectedIndex,
        option : e.target.childNodes[index],
        langCode: option.getAttribute('langcode'),
        transName: e.target.value
      })

          let audio_translate_caption = {
          name : e.target.value,
          code : langCode,
          translate_caption : "true"
        };
        
        dispatch(saveTransAudioCaption(audio_translate_caption));
      }

      

      const changeCaptionLanguage = () => {
        const config = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
          setTranslatedCaption([]);
          setLoader(true)
          const formData = new FormData();
          formData.append("user_id", user_id);
          formData.append("campaignid", trimAudio.campaign_id);
          formData.append("translate_caption", "yes");
          formData.append("code",  translatedLang === null ? translate.langCode : translatedLang.code);
          formData.append("name", translatedLang === null ?  translate.transName : translatedLang.name);
          formData.append("transcribe_json", JSON.stringify(sentences));
          
              axios.post(`${baseURL}api/caption_translateprocess`, formData, config)
              .then((res) =>{
                if(res.data.status === true){
                  setTranslated(true);
                  setTranslatedCaption(res.data.data);
                  dispatch(translatedCaptions(res.data.data))
                  setLoader(false);
                }
              })
      }
     
      const changeCaptionText = (e, index) => {
          dispatch(updatedTranslatedCaptions(e.target.value, index))
      }

      const changeGeneratedText = (e, index) => {
        dispatch(updatedGeneratedCaptions(e.target.value, index))
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-caption" role="tabpanel" aria-labelledby="v-pills-caption-tab">
            <div className="row">
                <div className="col-md-12">
                    <div className="general-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="caption-content">
                                    <div className="div" style={{display:"flex", justifyContent:"space-between"}}>
                                    <span className="d-block">Your Generated Caption</span>
                                    <span className="d-block">Translate Caption</span>
                                    </div>
                                    
                                    <div className="dropdown mt-4" style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                         
                                          <select className="form-select" aria-label="Default select example" onChange={audioTransCaption} style={{width:"120px"}}>
                                            {
                                              translatedLang === null || translatedLang.name === "" ? <option selected disabled >Select</option> : <option selected >{translatedLang.name}</option>
                                            }
                                                {
                                                    lang.map((l) => {
                                                    return <option key={l.id} value={l.name} langcode={l.code} >{l.name}</option>
                                                  })
                                                }
                                          </select>
                                          
                                        </div>
                                        <button className="translate_btn" onClick={changeCaptionLanguage} >Translate</button> 
                                    <div className="transcribe"> 
                                      {loader ? 
                                            <div class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            : null}
                                      
                                    { 
                                      translated ? 
                                         translatedCaption.map((tc, index) => {
                                          return(
                                            <div className="main_transcribe">
                                              <div className="timing">
                                                <li>{tc.starttime}</li>
                                                <li>{tc.endtime}</li>
                                              </div>
                                              <div className="text"><textarea name="sentence" cols="30" rows="4" onChange={(e) => changeCaptionText(e, index)} value={changeCaption} >{tc.content}</textarea></div>
                                            </div>
                                          )
                                        }) 
                                      :
                                       sentences.map((sentence, index) => {
                                        return(
                                          <div className="main_transcribe">
                                            <div className="timing">
                                              <li>{sentence.start_time}</li>
                                              <li>{sentence.end_time}</li>
                                            </div>
                                            <div className="text"><textarea name="sentence" cols="30" rows="4" onChange={(e) => changeGeneratedText(e, index)} value={changeCaption}>{sentence.text}</textarea></div>
                                          </div>
                                        )
                                      })
                                      }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Caption;
