import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  addClientAccount,
  addTeamAccount,
} from "../../actions/accountManagement";
import { setLoader } from "../../actions/loader";


function AddAccount({
  auth: { user, loading },
  addClientAccount,
  addTeamAccount,
  setLoader,
  loader,
  showAlert,
}) {
  const [user_id, setUserId] = useState("");
  const [accountDetail, setAccountDetail] = useState({
    firstName: "",
    password: "",
    email: "",
    accountType: "client",
  });

  const [check, setCheck] = useState(false);


  // Fetch Id
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);
  
  const addAccountDetail = (e) => {
    setAccountDetail({
      ...accountDetail,
      [e.target.name]: e.target.value,
    });
  };

  // Submit Form
  const addAccount = async (e) => {
    e.preventDefault();
    setCheck(true);
    showAlert(true);
    // const data = {
    //   user_id: user_id,
    //   Firstname: accountDetail.firstName.split(" ")[0],
    //   Lastname: accountDetail.firstName.split(" ").slice(1)
    //     ? accountDetail.firstName.split(" ").slice(1).join(" ")
    //     : "",
    //   email: accountDetail.email,
    //   password: accountDetail.password,
    // };
    const data = {
      name: accountDetail.firstName,
      email: accountDetail.email,
      password: accountDetail.password,
      type:accountDetail.accountType,
      user_id:user_id
    };



    setLoader();
    if (accountDetail.accountType === "client") {
      await addClientAccount(data);
    } else if (accountDetail.accountType === "team") {
      await addTeamAccount(data);
    }
    console.log(addTeamAccount)
    setAccountDetail({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      accountType: "client",
    });
    setCheck(false);
  };
  



  return (
    <div className="container px-4 mt-5 mb-0">
      <h6
        className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
        style={{ fontSize: "18px !important" }}
      >
        Add Account
      </h6>
      <div className="bg-table">
        <form onSubmit={(e) => addAccount(e)}>
          <div className="row d-flex justify-content-center ">
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Account Type </label>
                <select
                  className="form-control input-account-type p-accnt"
                  id="Account_selector"
                  name="accountType"
                  value={accountDetail.accountType}
                  onChange={(e) => addAccountDetail(e)}
                >
                  <option value="team" id="yt_video">
                    Team Member{" "}
                  </option>
                  <option value="client" id="custom_video">
                    Client Account{" "}
                  </option>
                </select>
              </div>
            </div>
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Name </label>
                <input
                  className="form-control input-dark-accnt personal-info  w-100"
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  value={accountDetail.firstName}
                  onChange={(e) => addAccountDetail(e)}
                  required
                />
              </div>
            </div>

            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Password </label>
                <input
                  className="form-control input-dark-accnt personal-info w-100"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={accountDetail.password}
                  required
                  onChange={(e) => addAccountDetail(e)}
                  autoComplete="off"
                />
                <span
                  className="input-show"
                  onClick={(e) =>
                    e.target.parentElement.previousElementSibling.type ===
                    "password"
                      ? (e.target.parentElement.previousElementSibling.type =
                          "text")
                      : (e.target.parentElement.previousElementSibling.type =
                          "password")
                  }
                >
                  {" "}
                  <img
                    src="./assets/images/show.svg"
                    alt=""
                    id="#password"
                    className="show-svg"
                  />{" "}
                </span>
              </div>
            </div>
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Email Address </label>
                <input
                  className="form-control input-dark-accnt personal-info"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={accountDetail.email}
                  onChange={(e) => addAccountDetail(e)}
                  autoComplete="off"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col col-md-12 text-center mt-3">
            {loader && check ? (
              <button
                className="btn btn-3 accnt-mngmt-btn mt-4"
                type="button"
                disabled
                style={{
                  background: "#1cb7f2",
                  font: "16px",
                  color: "white",
                }}
              >
                <span
                  className="spinner-border spinner-border-sm text-light mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <strong>Loading...</strong>
              </button>
            ) : (
              <button className="btn btn-3 accnt-mngmt-btn mt-4">Save</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

AddAccount.propTypes = {
  addClientAccount: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  addTeamAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loader: PropTypes.bool,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  addClientAccount,
  setLoader,
  addTeamAccount,
})(AddAccount);
