import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import PropTypes from "prop-types";


function Profile({ logout, user, loading }) {
  const [profile_pic, setProfilePic] = useState("");
  const [userId, setUserId] = useState("");
  const [is_client, setIsClient] = useState("");
  const [is_team, setIsTeam] = useState(false);
  const [accountType, setAccountType] = useState([]);
  

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.role ? null : user.role);
      setIsTeam(loading || !user.is_team ? false : user.is_team);
    }

  }, [user, loading]);

  useEffect(() => {
    setProfilePic(loading || !user ? "" : `${user.profile_pic}`);
    setUserId(loading || !user ? "" : user.user_id);
  }, [user, loading]);
 
  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  return (
    <>
      <li className="nav-item nav-item-7 dropdown">
        <Link
          id="avatar"
          className="nav-link text-white pr-0"
          to="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/* <span><i class="far fa-user"></i></span> */}
         <img
            className="img-fluid avatar-image rounded-circle"
            src={
              profile_pic  ? profile_pic : "./assets/images/empty_profile.png"
            }
            alt="Avatar"
          />
          {/* <span className="avatar-notification" /> */}
        </Link>
        <div
          className="dropdown-menu dropdown-menu-arrow dropdown-scroll-height"
          aria-labelledby="navbarDropdown"
        > 
        
        {is_client === '3' || is_client === '2' ? null : (
            <Link className="dropdown-item" to="/profile">
            <i className="fa fa-user" aria-hidden="true"></i>
            <i className="pl-2" style={{ fontStyle: "normal" }}>
              My Account
            </i>
          </Link>
        )}
          


          {is_client === '3' || is_client === '2' ? null : (
            <Link className="dropdown-item" to="/privacy">
              <i className="fa fa-key" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Privacy Settings
              </i>
            </Link>
          )}
          {/* {is_client === true ? null : (
            <Link className="dropdown-item" to="./notifications">
              Notifications
            </Link>
          )} */}
          {is_client === '3' || is_client === '2' ? null : (
            <Link className="dropdown-item" to="./account">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Account Management
              </i>
            </Link>
          )}

          {/* {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./fb-templates">
              <i className="fa fa-plus-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Upgrades
              </i>
            </Link>
          ) : null} */}

          
          {/* {is_client === '3' ? null : (
            <Link className="dropdown-item" to="./upgrades">
              <i className="fa fa-plus-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Upgrades
              </i>
            </Link>
          )} */}

          {/*  {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) || accountType.includes("unlimited") ? (
            <Link className="dropdown-item" to="./video-flix">
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Video Flix
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) || accountType.includes("dfy") ? (
            <Link className="dropdown-item" to="./udemy-style">
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Udemy Style website
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) || accountType.includes("dfy") ? (
            <Link className="dropdown-item" to="./dfy-course">
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                DFY Course
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./client-contract">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Client Contract
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./reseller-license">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Reseller License
              </i>
            </Link>
          ) : null} */}

          {is_client === true ? null : accountType.includes("unlimited") ||
            accountType.includes("lite") ||
            accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./reel-merge">
              <i className="fa fa-files-o" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                ReelMerge
              </i>
            </Link>
          ) : null}

          {is_client === true ? null : accountType.includes("unlimited") ||
            accountType.includes("lite") ||
            accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./my-merge-video">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                My Merge Videos
              </i>
            </Link>
          ) : null}

          {is_client === true ? null : accountType.includes("unlimited") ? (
            <Link className="dropdown-item" to="./whitelabel">
              <i className="fa fa-files-o" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                White Label
              </i>
            </Link>
          ) : null}

          {/* {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./web-hosting">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Web Hosting
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./video-commercial">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Video Commercial
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) || accountType.includes("dfy") ? (
            <Link className="dropdown-item" to="./video-promos">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Video Promos
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <a
              className="dropdown-item"
              href="!#"
              onClick={(e) => {
                e.preventDefault();
                window.location =
                  "https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip";
              }}
            >
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                DFY Lead Magnets
              </i>
            </a>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./agency-website">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Agency Website
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) ||
            accountType.includes("dfylite") ||
            accountType.includes("dfy") ? (
            <Link className="dropdown-item" to="./social-media-assets">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Social Media Assets
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "bundle"
            ) || accountType.includes("dfy") ? (
            <Link className="dropdown-item" to="./dfy-articles">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                DFY Articles
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./rebranding">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                WL Rebranding
              </i>
            </Link>
          ) : null}

          {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./business-finder">
              <i className="fa fa-users" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Business Finder
              </i>
            </Link>
          ) : null} */}

          <Link className="dropdown-item" to="./help-and-support">
            <i className="fa fa-headphones" aria-hidden="true"></i>
            <i className="pl-2" style={{ fontStyle: "normal" }}>
              Help & Support
            </i>
          </Link>

          <div className="dropdown-divider" />
          <Link
            to="!#"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              logout(userId);
              return <Redirect to="/" />;
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <i className="pl-2" style={{ fontStyle: "normal" }}>
                  Sign Out
                </i>
              </div>
              <div className="pr-3">
                <img
                  src="./assets/images/right-arrow.svg"
                  width="12px"
                  height="12px"
                  alt=""
                />
              </div>
            </div>
          </Link>
        </div>
      </li>
    </>
  );
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { logout })(Profile);
