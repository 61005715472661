import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { removeAlert, setAlert } from "../../actions/alert";
import { saveUploadAudioUrl } from "../../actions/createPodreel";
import styled from "styled-components";
import { connect } from "react-redux";

import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import Alert from "../alert/Alert";

//step-wise component 1,2,3

// progress bar
const Upload = ({
  auth: { user, loading },
  setNext,
  saveUploadAudioUrl,
  setAlert,
  removeAlert,
}) => {
  const [user_id, setUserId] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadLoader, setUploadLoader] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const uploadAudio = async (e) => {
    console.log(e.target.files[0]);
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 10) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (fileExtension === "mp3") {
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("audio", e.target.files[0]);

        formData.append("user_id", user_id);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}api/audio-upload`,
            formData,
            config
          );

          if (res.data.status === true) {
            setAudioUrl(res.data.data.url);
            setUploadLoader(false);
            setSelectedFile({});
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(mp3)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload audio more then 10MB in size", "danger");
    }
  };

  return (
    <UploadStyle>
      <div>
        <div className='row centerd'>
          <div
            className=' col-8 p-0'
            style={{
              height: "20vh",
              width: "100%",
              backgroundColor: "#181d21",
            }}
          >
            <div
              className='row d-flex justify-content-around align-items-center'
              style={{ height: "100%" }}
            >
              <div
                className='col-11 p-0'
                style={{
                  height: "75%",
                  width: "100%",
                  backgroundColor: "#363839",
                  border: "2px white dashed",
                }}
              >
                <form
                  onSubmit={(e) => e.target.preventDefault()}
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <div className='upload-btn-wrapper'>
                    <div>
                      <button
                        style={{
                          backgroundColor: "#363839",
                          color: "white",
                          border: "none",
                          fontSize: "1rem",
                        }}
                      >
                        {Object.keys(selectedFile).length === 0 &&
                        selectedFile.constructor === Object
                          ? "Upload File (MP3 upto 5MB is allowed)"
                          : selectedFile.name}
                      </button>
                      <i
                        className='fa fa-cloud-upload text-center'
                        aria-hidden='true'
                        style={{
                          display: "block",
                          color: "white",
                          fontSize: "1.7rem",
                          paddingTop: "10px",
                        }}
                      ></i>
                    </div>
                    <input
                      type='file'
                      className='custom-file-input'
                      id='customFile'
                      name='file'
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "0",
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        uploadAudio(e);
                      }}
                    />

                    {/* <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {Object.keys(selectedFile).length === 0 &&
                                      selectedFile.constructor === Object
                                        ? "Choose File"
                                        : selectedFile.name}
                                    </label> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-center centerd'>
          <button
            className='btn btn-danger '
            disabled={audioUrl === "" ? true : false}
            style={{ backgroundColor: "#F7473A " }}
            onClick={(e) => {
              e.preventDefault();
              saveUploadAudioUrl(audioUrl);
              setNext((value) => {
                return { upload: false, audio: true, customize: false };
              });
            }}
          >
            {" "}
            Proceed{" "}
          </button>
        </div>
      </div>
    </UploadStyle>
  );
};

const UploadStyle = styled.div`
  .edit_part {
    width: 100%;
    margin: 0;
  }

  .edit_tab {
    border-radius: 0;
    font-size: 1rem !important;
    border: none;
  }

  .edit_tab:hover {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    );
    color: #fff !important;
  }

  li .active {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    ) !important;
    color: #fff !important;
  }

  .tab-content {
    overflow-y: scroll;
    height: 59vh !important;
    overflow-x: hidden;
  }

  .search_img:hover {
    cursor: pointer;
    border: 3px solid #1cb7f2 !important;
  }

  .search-area {
    background: #171717 !important;
    height: 100% !important;
    border-radius: 5px;
  }
  .search-row {
    height: 9rem !important;
  }
  .page {
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
    width: 100px;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: #171717 !important;
    color: white;
    cursor: pointer;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #1cb7f2;
    border-radius: 50%;
  }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  saveUploadAudioUrl,
  setAlert,
  removeAlert,
})(Upload);
