import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { fetchAllProject, updateProject } from "../../actions/video";
import {
  saveUploadAudioUrl,
  editedData,
  generatedCaptions,
  fetchSocialAccounts,
} from "../../actions/createPodreel";
import { useHistory, withRouter } from "react-router-dom";
import { baseURL } from "../../global/global";
import Alert from "../alert/Alert";
import axios from "axios";
import { removeAlert, setAlert } from "../../actions/alert";
import { connect, useDispatch, useSelector } from "react-redux";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import Loader from "../loader/Loader";
import youtubeEnable from "../../assets/images/YT-DEFAULT.png";

// import PublishModal from "../publishModal/PublishModal";
import projectImg from "../../assets/images/createdVideoImg.png";

function Video({
  auth: { user, loading },
  fetchAllProject,
  project,
  setAlert,
  removeAlert,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user_id, setUserId] = useState("");
  const [projects, setProjects] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const p = useSelector((state) => state.video);
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const close = () => {
    setModal({ isOpen: false });
  };

  const youtube = useSelector((state) => state.integration.youtube);
  const [modal1, setModal1] = useState(false);
  const [socialData, setSocialData] = useState([]);

  const [postData, setPostData] = useState(youtube);
  const [loader, setLoader] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [currSocial, setCurrSocial] = useState([]);
  const [videoUrl, setVideoUrl] = useState(false);
  useEffect(() => {
    if (auth.user !== null) {
      dispatch(fetchSocialAccounts(auth.user.user_id));
    }
  }, [auth]);
  useEffect(() => {
    if (youtube.length > 0) {
      setSocialData(youtube);
    }
    setCurrSocial(youtube);
  }, [youtube]);

  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    if (user_id) {
      fetchAllProject({ user_id });
    }
  }, [user_id, fetchAllProject]);

  useEffect(() => {
    setProjects(p.allProject);
  }, [project.allProject, project.loading]);

  // useEffect(() => {
  // let pr = project.allProject.filter(pj => pj.is_active === "2");
  // let pr1 = project.allProject.filter(pj => pj.is_active === "5");

  // if(pr.length === 0 && pr1.length !== 0){
  //   const interval1 = setInterval(() => {

  //       setRefresh(!refresh)

  //   },5000);
  //   if(pr.length === 0 && pr1.length === 0){
  //     clearInterval(interval1);
  //   }
  // }

  // })

  //Realtime Status
  useEffect(() => {
    const interval = setInterval(() => {
      let pr = project.allProject.filter((pj) => pj.is_active === "2");
      let pr1 = project.allProject.filter((pj) => pj.is_active === "5");
      // let inProgressId = pr.length > 0 ? pr[0].id : "";
      let inProgressId =
        pr.length > 0 ? pr[0].id : pr1.length > 0 ? pr1[0].id : "";

      const formData = new FormData();
      formData.append("postcast_id", inProgressId);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      // if(pr.length === 0 && pr1.length !== 0){
      //   setRefresh(!refresh);
      // }else if(pr.length === 0 && pr1.length === 0){
      //   clearInterval(interval)
      // }

      if (pr.length === 0 && pr1.length === 0) {
        clearInterval(interval);
      }

      axios
        .post(`${baseURL}api/podcast-status`, formData, config)
        .then((res) => {
          if (res.data.status === true && res.data.data.is_active !== "2") {
            dispatch(updateProject(res.data.data));
            clearInterval(interval);
          } else if (
            res.data.status === true &&
            res.data.data.is_active === "2"
          ) {
            dispatch(updateProject(res.data.data));
            clearInterval(interval);
          }
        });
    }, 5000);
  });

  //Edit Podcast
  const editPodcast = (id, e) => {
    history.push("/ProfileSection");
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("postcast_id", id);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios.post(`${baseURL}api/podcast-edit`, formData, config).then((res) => {
      if (res.data.status === true) {
        dispatch(editedData(res.data.data));
      }
    });
  };

  //Delete Podcast
  const deleteProjectByModal = () => {
    deleteProject(deleteData);
  };

  const deleteProject = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(
        `${baseURL}api/podcast-delete`,
        body,
        config
      );
      if (res.data.status === true) {
        fetchAllProject({ user_id });
        setAlert(res.data.message, "success");
      } else {
        setAlert(res.data.message, "danger");
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  // Date-Format

  const dateToYMD = (date) => {
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.getDate();
    var m = strArray[date.getMonth() - 1];
    var y = date.getFullYear();
    return "" + (d <= 9 ? "0" + d : d) + " " + m + ", " + y;
  };

  const currentSocial = (social, type, icon) => {
    setCurrSocial(social);
    // setSelectIcon(icon);

    setPostData({
      ...postData,
      type: type,
      integration_id: social[0].id,
      network: icon,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    postData.user_id = auth.user.user_id;
    postData.video_url = videoUrl;

    axios({
      method: "POST",
      url: `${baseURL}api/upload-to-youtube`,
      data: postData,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(setAlert(res.data.message, "success"));
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
        setShowModel(false);
        setLoader(false);
      })
      .catch((err) => {
        setShowModel(false);
        setLoader(false);
      });
  };

  const onInputChange = (e) => {
    setPostData({ ...postData, [e.target.name]: e.target.value });
  };

  const setVideoStatus = (project) => {
    setShowModel(true);
    setVideoUrl(project.output_video);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Alert />

      {/* <div
              className="col colmn-3 col-xl-3 col-md-6 col-sm-9 col-12 px-2 mb-lg-0 mb-5 mt-3"
            > */}

      {/* style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", alignItems:"center"}} */}
      <div className="projects row">
        {project.loading ? (
          <>
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "40vh" }}
            >
              <Loader />
            </div>
          </>
        ) : projects.length <= 0 ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "40vh" }}
          >
            <h4>
              You have not created any video yet. Click "Create Videos" to start
              creating stunning videos.
            </h4>
          </div>
        ) : (
          projects.slice(0, 8).map((project) => {
            return (
              <div
                className="card-wrapper col-md-6 col-lg-3 col-sm-6 p-2"
                style={{ width: "328px", marginBottom: "13px" }}
                key={project.id}
              >
                <div
                  className="status w-100 d-flex align-items-center justify-content-center mb-0"
                  style={{ backgroundColor: "aliceblue" }}
                >
                  {/* <img
                      className="img-fluid poster"
                      // src={`${project.image}`}
                      src={project.thumbnail_img}
                      alt=""
                    /> */}
                  {project.is_active === "0" ? (
                    <h4 style={{ textAlign: "center" }}>
                      Awaiting Customization
                    </h4>
                  ) : (
                    ""
                  )}
                  {project.is_active === "1" ? (
                    <h4 style={{ textAlign: "center" }}>Rendering</h4>
                  ) : (
                    ""
                  )}
                  {project.is_active === "2" ? (
                    <h4 style={{ textAlign: "center" }}>Processing</h4>
                  ) : (
                    ""
                  )}

                  {(() => {
                    if (
                      project.thumbnail_img !== "" &&
                      project.is_active === "3"
                    ) {
                      return (
                        <img
                          src={project.thumbnail_img}
                          alt="thumbnail"
                          className="img-fluid poster"
                        />
                      );
                    } else if (
                      project.thumbnail_img === "" &&
                      project.is_active === "3"
                    ) {
                      return (
                        <h4 style={{ textAlign: "center" }}>Render Success</h4>
                      );
                    }
                  })()}

                  {/* { 
                      project.is_active === "3" ? <h4 style={{textAlign:"center"}}>Rendering Success</h4> : ""
                    } */}
                  {project.is_active === "4" ? (
                    <h4 style={{ textAlign: "center" }}>Rendering Failed</h4>
                  ) : (
                    ""
                  )}
                  {project.is_active === "5" ? (
                    <h4 style={{ textAlign: "center" }}>Added To Queue</h4>
                  ) : (
                    ""
                  )}
                  <div className="actions-icon row align-items-center mx-0">
                    <>
                      <div className="icon-wrapper">
                        <a
                          href="!#"
                          className="action-icon edit-icon mb-1"
                          onClick={(e) => {
                            e.preventDefault();
                            editPodcast(project.id);
                          }}
                        >
                          <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                          </svg>
                        </a>
                        <p className="sm-txt text-white">Edit</p>
                      </div>
                    </>

                    {project.is_active === "3" ? (
                      <>
                        <div className="icon-wrapper ">
                          <a
                            href={`https://app.podreel.io/api/donwload-file?file=${project.output_video}`}
                            className="action-icon download-icon mb-1"
                            download={project.podcast_name}
                          >
                            <svg
                              width="20px"
                              height="20px"
                              viewBox=" 0 0 20 20"
                            >
                              <use xlinkHref="./assets/images/download.svg#download-ico" />
                            </svg>
                          </a>
                          <p className="sm-txt text-white">Download</p>
                        </div>
                        <div className="icon-wrapper ">
                          <a
                            onClick={() => setVideoStatus(project)}
                            className=" action-icon download-icon mb-1"
                            download={project.podcast_name}
                          >
                            {/* <svg
                              width="20px"
                              height="20px"
                              viewBox=" 0 0 20 20"
                            >
                              <use xlinkHref="./assets/images/download.svg#download-ico" />
                            </svg> */}
                            <i className="fas fa-cloud-upload-alt"></i>
                          </a>
                          <p className="sm-txt text-white">Publish</p>
                        </div>
                      </>
                    ) : null}

                    <>
                      <div className="icon-wrapper">
                        <a
                          href="!#"
                          className="action-icon delete-icon remove mb-1"
                          data-toggle="modal"
                          data-target="#confirm-del"
                          onClick={(e) => {
                            e.preventDefault();
                            removeAlert();
                            setDeleteData({
                              id: project.id,
                              user_id: user_id,
                            });
                            setModal({ isOpen: true });
                          }}
                        >
                          <svg width="20px" height="20px" viewBox=" 0 0 20 20">
                            <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                          </svg>
                        </a>
                        <p className="sm-txt text-white">Delete</p>
                      </div>
                    </>
                  </div>
                </div>
                <div className="details-wrapper d-flex justify-content-between">
                  <div className="meta-wrapper flex-grow-1">
                    <div className="date mb-1 text-left px-1">
                      <span className="sm-txt text-white ">
                        {project.podcast_name}
                      </span>
                    </div>
                  </div>
                  <div className="time">
                    <span className="sm-txt text-white">
                      {dateToYMD(
                        new Date(
                          parseInt(project.created.split("-")[0]),
                          parseInt(project.created.split("-")[1]),
                          parseInt(project.created.split("-")[2])
                        )
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {/* </div> */}

      {modal.isOpen ? (
        <ConfirmDelete close={close} confirm={deleteProjectByModal} />
      ) : null}
      {/* 
      {publishModal.isOpen ? (
        <PublishModal videoData={publishModal} close={setPublishModal} />
      ) : null} */}

      <div className={`modal ${showModel ? "show-modal" : ""} `}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" style={{ width: "100%" }}>
                Publish This Video
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setShowModel(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i
                    className="fas fa-times-circle"
                    style={{ color: "#ffffff" }}
                  ></i>
                </span>
              </button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#060818" }}>
              <div className="icon-response">
                {socialData.length > 0 ? (
                  <span
                    className="icon-response-span"
                    onClick={() =>
                      currentSocial(
                        socialData,
                        "api/upload-to-youtube",
                        "youtube"
                      )
                    }
                  >
                    {/* <SocialIcons
                      icon={youtubeIcon}
                      name="YouTube"
                      checkName="youtube"
                      selectIcon={selectIcon}
                    /> */}
                    {/* <div className="div">
                      <img src={youtubeEnable} alt="" />
                    </div> */}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="form-body">
                <h5 className="text-center" style={{ color: "#fff" }}>
                  Publish To YouTube
                </h5>
                <form
                  className="gdpr-update-email"
                  method="post"
                  onSubmit={(e) => onFormSubmit(e)}
                >
                  <div className="form-group">
                    <small className="form-text text-muted">Title</small>
                    <input
                      type="text"
                      className="form-control"
                      required
                      name="title"
                      onChange={(e) => onInputChange(e)}
                      placeholder="Enter Title"
                      style={{ color: "#000" }}
                    />
                  </div>
                  <div className="form-group">
                    <small className="form-text text-muted">Description</small>
                    <textarea
                      className="form-control"
                      required
                      name="description"
                      onChange={(e) => onInputChange(e)}
                      placeholder="Enter Description"
                    />
                  </div>
                  <div className="form-group">
                    <small className="form-text text-muted">Account Type</small>
                    <select
                      className="form-control"
                      required
                      name="integration_id"
                      onChange={(e) => onInputChange(e)}
                    >
                      <option disabled selected>
                        select account
                      </option>
                      {currSocial.length > 0
                        ? currSocial.map((item, index) => {
                            return (
                              <option
                                key={index}
                                style={{ backgroundColor: "#000" }}
                                value={item.id}
                              >
                                {item?.username}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn-custom btn-change7 my-custom"
                      disabled={loader}
                      style={{ background: "#ea5e5f" }}
                    >
                      {loader ? (
                        <i className="fa fa-spinner fa-spin mr-2" />
                      ) : (
                        ""
                      )}{" "}
                      Publish
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.video,
});

Video.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  fetchAllProject: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchAllProject,
  setAlert,
  removeAlert,
})(withRouter(Video));
