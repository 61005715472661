import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import axios from "axios";
import { baseURL } from "../../../../global/global";
import {
  saveTextData,
  addText,
  addTextColor,
  addTextFont,
} from "../../../../actions/createPodreel";
import { connect, useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring";
import { textPosition, addFontSize } from "../../../../actions/createPodreel";

const Text = (props) => {
  const popover1 = {
    position: "absolute",
    zIndex: "2",
    left: "12px",
    top: "30px",
  };

  const cover1 = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const [text, setTextArray] = useState([]);
  const dispatch = useDispatch();
  const [showColorP1, setShowColorP1] = useState(false);
  const [background, setBackground] = useState("#fff");
  const [fonts, setFonts] = useState([]);
  const [fields, setFields] = useState([{ value: null }]);
  const [textAreaId, setTextAreaId] = useState();
  const [id, setId] = useState();
  const [idForChange, setIdForChange] = useState();

  const textStateData = useSelector(
    (state) => state.createPodreel.customize.text
  );

  const position = useSelector(
    (state) => state.createPodreel.text_position.position
  );

  useEffect(() => {
    if (textStateData.length !== 0) {
      let selectedIndex = textStateData.findIndex(
        ({ isSelected }) => isSelected === true
      );
      setIdForChange(selectedIndex);
    }
  }, [textStateData]);

  const handleChangeComplete1 = (color) => {
    setBackground(color.hex);
    console.log(idForChange);
    dispatch(addTextColor(color.hex, idForChange));
  };

  const setFontFamily = (e) => {
    console.log(e.target.value.split("__"));
    dispatch(addTextFont(e.target.value.split("__"), idForChange));
  };

  const showColorPicker1 = (e, i) => {
    setTextAreaId(i);
    setShowColorP1(!showColorP1);
  };
  const handleClose1 = () => {
    setShowColorP1(false);
  };

  const textObject = {
    text: "Click Here To Add Text",
    id: randomstring.generate(7),
    color: "#000",
    fontfamily: "Zilla Slab",
    fontUrl:
      "http://fonts.gstatic.com/s/zillaslab/v3/GQa6C2kQZDjk1E7wBSIhnPesZW2xOQ-xsNqO47m55DA.ttf",
    fontsize: 15,
    width: 300,
    height: 50,
    posy: 0,
    posx: 0,
    isSelected: false,
    position: position,
  };

  const addTextArea = () => {
    props.saveTextData(textObject);
  };

  //Fetching fonts
  useEffect(() => {
    const data = { page_number: 1 };
    axios.post(`${baseURL}api/fonts-get`, data).then((res) => {
      if (res.data.status === true) {
        setFonts(res.data.data);
      }
    });
  }, []);

  const setTextPosition = (e) => {
    dispatch(textPosition(e.target.value, idForChange));
  };

  const changeFontSize = (e) => {
    console.log(e.target.value);
    dispatch(addFontSize(e.target.value, idForChange));
  };

  return (
    <div
      className="tab-pane fade"
      id="v-pills-settings"
      role="tabpanel"
      aria-labelledby="v-pills-settings-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <ul>
            <li>
              <button
                className="btn btn-primary"
                onClick={addTextArea}
                style={{ marginTop: "24px", marginLeft: "13%" }}
              >
                Click Here To Insert Text
              </button>
            </li>

            <li>
              <div className="form-group">
                <div className="color">
                  <div className="colorpickers d-flex align-items-center mt-4">
                    <div className="background">
                      <h6>Color</h6>
                    </div>
                    <div className="colorpicke">
                      <div id="cp-component2" className="input-group">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          style={{
                            width: "70px",
                            height: "35px",
                            backgroundColor: background,
                            marginLeft: "10px",
                            border: "none",
                            float: "right",
                            position: "relative",
                          }}
                          onClick={showColorPicker1}
                        >
                          {" "}
                        </button>

                        {showColorP1 ? (
                          <div style={popover1}>
                            <div style={cover1} onClick={handleClose1} />
                            <SketchPicker
                              color={background}
                              onChange={handleChangeComplete1}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div className="form-group">
                <div className="font-style d-flex align-items-center mt-4">
                  <div className="background">
                    <h6>Font</h6>
                  </div>
                  <div className="font" style={{ marginLeft: "10px" }}>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setFontFamily(e)}
                    >
                      <option selected disabled>
                        Select
                      </option>
                      {fonts.map((font) => {
                        return (
                          <option
                            value={font.url + "__" + font.family}
                            key={font.family}
                          >
                            {font.family}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </li>

            <li className="font-size">
              {" "}
              <div>
                <h6
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    color: "#999c9e !important",
                  }}
                >
                  Font Size
                </h6>
              </div>
              <input
                type="number"
                min="1"
                defaultValue="15"
                onChange={(e) => changeFontSize(e)}
              />
            </li>

            <li>
              <div className="form-group">
                <div className="font-style d-flex align-items-center mt-4">
                  <div className="background">
                    <h6>Position</h6>
                  </div>
                  <div className="font" style={{ marginLeft: "10px" }}>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setTextPosition(e)}
                    >
                      <option selected disabled>
                        Select
                      </option>
                      <option value="top-left">Top-Left</option>
                      <option value="top-center">Top-Center</option>
                      <option value="top-right">Top-Right</option>
                      <option value="center-left">Left</option>
                      <option value="centered">Center</option>
                      <option value="center-right">Right</option>
                      <option value="bottom-left">Bottom-Left</option>
                      <option value="bottom-center">Bottom-Center</option>
                      <option value="bottom-right">Bottom-Right</option>
                    </select>
                  </div>
                  <i
                    className="fas fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Select text then assign a position."
                    style={{ marginLeft: "3px" }}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customize: state.createPodreel.customize,
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveTextData: (data) => dispatch(saveTextData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Text);
